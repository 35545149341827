<template>
  <main class="Financial">
    <TheSubHeader
      ref="theSubHeader"   
      icon="money"
      title="Comprovantes"
      page-title-tag="Financial Receipts"
      type="routerTabs"
      organizer-columns="600px"
    >
      <template #afterButtons>
        <BaseButton
          v-if="permissions && permissions.editVehicles && permissions.editVehicles.isVisible"
          icon="truck-delivered"
          color="primary-base"
          label="Tipo de Veículo"
          class="car-type-button"
          :track="trackSubheaderButtonString('Financial Receipts', 'Financial Edit Vehicle')"
          @click="goToEditVehicle()"
        />
        <BaseButton
          icon="status"
          color="primary-base"
          label="Custo de operação"
          class="operation-button"
          :track="trackSubheaderButtonString('Financial Receipts', 'Operation Cost')"
          @click="openModalOperation()"
        />
      </template>
      <template #filter>
        <section
          class="Financial__subheaderTab"
        >     
          <!-- Drivers filter -->
          <BaseTreeSelect
            v-model="filters.drivers"
            name="Motorista"
            label="Motorista / Beneficiário"
            placeholder="Pesquisar"
            multiple
            suggestions="searchDrivers"
            @input="updateTags(), fetchReceipts()"
          />

          <!-- Date filter -->
          <BaseDatePicker
            v-model="filters.monthYear"
            label="Selecione mês e ano"
            type="month"
            :range="false"
            placeholder="Selecione o mês"
          />

          <BaseTreeSelect
            v-model="filters.fortnight"
            name="Quinzena"
            label="Quinzena"
            placeholder="Selecione a Quinzena"
            :select-options="filters.fortnightOptions"
            :read-only="!filters.monthYear"
            @input="updateTags(), fetchReceipts()"
          />
        </section>
      </template>

      <!-- filters tag -->
      <template v-if="allTags.length > 0" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>

    <section class="Financial__content">
      <DataTable
        :header="receipts.data.headers"
        :table="receipts.data.content"
        @generateExtract="generateExtract"
        @generateReceipt="generateReceipt"
      />
      <Pagination @page="fetchReceipts" @paginate="fetchReceipts" />
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { TheSubHeader } from '@/components/organisms'
import { BaseButton, BaseDatePicker, BaseTreeSelect } from '@/components/atoms'
import FinancialMixin from '@/mixins/financial'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { financial } from '@/api'

export default {
  name: 'FinancialConsolidated',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    Pagination,
    FiltersTag
  },
  mixins:[FinancialMixin],

  data() {
    return {
      filters: {
        monthYear: null,
        fortnightOptions: [{ id: 1, name:'1ª Quinzena' }, { id: 2, name:'2ª Quinzena' }],
        fortnight: null,
        drivers: [],
      },
      allTags: [],
    }
  },
  computed: {
    ...mapState({
      paginate: state => state.pagination.paginate,
      page: state => state.pagination.page,
      receipts: state => state.financial.receipts,
      permissions: state => state.auth.permissions,
    })
  },

  mounted() {
    this.fetchReceipts()
  },

  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      fetchReceiptsHistory: 'financial/fetchReceiptsHistory'
    }),

    updateTags(){
      this.allTags = [...this.filters.drivers]
    },

    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.drivers = this.filters.drivers.filter((tag) => tag.name !== name)
      this.fetchReceipts()
    },

    clearAllTags(){
      this.allTags = []
      this.filters.drivers = []
      this.fetchReceipts()
    },

    async fetchReceipts() {
      let formatedDate
      if(this.filters.monthYear) {
        const date = new Date(this.filters?.monthYear)
        formatedDate = `${this.filters.fortnight?.id}*${date.getMonth() + 1}-${date.getFullYear()}`
      }

      let payload = {
        page: this.page,
        paginate: this.paginate,
        filters: {
          driver_id: this.filters.drivers,
          period: formatedDate
        }
      }

      await this.fetchReceiptsHistory(payload, (r) => r, (e) => e, () => this.setLoading(false))
      this.setPagination(this.receipts)
    },

    async generateExtract(data) {
      this.setLoading(true)
      await financial.downloadDriverExtract(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    },

    async generateReceipt(data) {
      this.setLoading(true)
      await financial.downloadDriverReceipt(data.id, (r) => r, (e) => e, () => this.setLoading(false))
    }
  }
}
</script>

<style lang="scss" scoped>
.Financial {
  &__subheaderTab {
    display: flex;
    align-items: flex-end;
    grid-gap: 1rem;
      @media #{$mobile-view} {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  &__content {
    margin: 0 auto;
    max-width: 1180px;
    width: 100%;
    max-width: 1180px;
    padding: 0 0 3rem 0;
    @media (max-width: $viewport-lg) {
      padding: 0 1rem 3rem 1rem;
    }
  }
}
</style>