import {mapActions, mapState } from 'vuex'

const financialMixin = {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      statusList: state => state.financial.statusList
    }),

    //Filters
    formattedDateRange: {
      get: function() {
        return this.filters ? [this.filters.dateRange.init, this.filters.dateRange.end] : []
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },
  },
  methods: {
    ...mapActions({
      fetchStatusList: 'financial/fetchStatusList'
    }),

    //Filters
    initStatusList() {
      this.fetchStatusList(this.$options.name)
    },

    //Modal
    openModalOperation() {
      this.$emit('openModalOperation')
    },

    goToEditVehicle() {
      this.$router.push({ name: 'editVehicles' })
    }
  },
}

export default financialMixin
